module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-bf30f49364/0/cache/gatsby-remark-images-npm-7.11.0-1a0bdec2e3-7abbac1ee9.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-cfeccf31ba/0/cache/gatsby-plugin-google-gtag-npm-5.11.0-fbf08da52a-4661bc1550.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-V622SDC3F4"],"gtagConfig":{}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-fb9e7e0f89/0/cache/gatsby-plugin-manifest-npm-5.11.0-857fd9ff94-785b9c333d.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Blog","short_name":"GatsbyJS","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0cb95a690c1e32032502766752990c7c"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-typography-virtual-c20903a5ed/0/cache/gatsby-plugin-typography-npm-5.11.0-0a39e54035-09196dbfed.zip/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-f2035b09b2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
